import React, { useEffect, useCallback } from "react";
import {
  InputAdornment,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Grid,
  Chip,
  Tooltip,
  Zoom,
  Badge,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import no_Data from "./../../../assets/images/png/Vector.png";
import { isEqual } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import useTable from "../../../components/UseTable";
import Controls from "../../../components/controls/Controls";
import { titleLabels } from "../../../constants/TitleLabels";
import TuneIcon from "@mui/icons-material/Tune";
import CommonDrawer from "../../../components/SideDrawer";
import ChargerdetailsSideBar from "./charger-sideBars/ChargerdetailsSideBar";
import FirmwareHistorySideBar from "./charger-sideBars/FirmwareHistorySideBar";
import Popup from "../../../components/Popup";
import ChargersExportCSV from "./exportCSV";
import { chargingModeEnum, firmwareStatusEnum } from "../CHDEnum";
import InfoIcon from "@mui/icons-material/Info";
import ScienceIcon from "@mui/icons-material/Science";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CableOutlinedIcon from "@mui/icons-material/CableOutlined";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useHistory } from "react-router-dom";
import { routePaths } from "../../../constants/RoutePaths";
import "./../ChargerHealth.scss";
import ChargerDetailDrawer from "../../../components/SideDrawerForChargerDetails";
import { PrivilegedComponent } from "../../../utils/PrivilegedComponent";
import EditChargerSideBar from "./charger-sideBars/EditChargerSideBar";
import NewCommonFilterDrawer from "../../../components/NewSideFIlterDrawer";
import DeleteConfirmDialog from "../../../components/NewDeleteConfirmDialog";
import AlertDialog from "../../../components/NewAlertDialog";
import {
  apiProvider,
  indexedEndPoints,
} from "../../../services/api/utilities/provider";
import ChargerLastKnownOCPP from "./charger-sideBars/ChargerLastKnownOcppSideBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChargerCredential from "./charger-sideBars/ChargerCredentialSideBar";
import { getCredentialByCharger } from "./chargerFirmwareService";
import DiagnosticsConfigIcon from "../../../assets/images/svg/DiagnosticsConfig.svg";
import { getData } from "../../../utils/Storage";
import { executeInternalApis } from "../../services/InternalApisService";
import environment from "../../../environment";

const useStyles = makeStyles((theme) => ({
  bar: {
    height: "100%",
    "&.low": {
      backgroundColor: "#f44336",
    },
    "&.medium": {
      backgroundColor: "#efbb5aa3",
    },
    "&.high": {
      backgroundColor: "#088208a3",
    },
  },
}));

const colors = {
  Enabled: "#00765E",
  Capable: "#22539B",
  Incapable: "#18191E",
  Disabled: "#18191E",
};
const backgrounds = {
  Enabled: "#E5F1EF",
  Capable: "#E7EBF3",
  Incapable: "#F0F1F8",
  Disabled: "#F0F1F8",
};

const pcColors = (publicChargingStatus) => {
  return colors[publicChargingStatus];
};

const pcBGColor = (publicChargingStatus) => {
  return backgrounds[publicChargingStatus];
};

const rfidColors = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#916D1F";
  } else {
    return colors[rfidObj?.status];
  }
};

const rfidBGColor = (rfidObj) => {
  if (rfidObj?.rfidConfigMode && rfidObj?.isConfigMatched == false) {
    return "#FEF8EB";
  } else {
    return backgrounds[rfidObj?.status];
  }
};

const headCells = [
  {
    id: "chargerName",
    label: "Charger name",
    styles: {
      position: "sticky",
      zIndex: 801,
      left: "0px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  {
    id: "vendorManufacturer",
    label: "Manufacturer (OEM)",
    styles: {
      position: "sticky",
      zIndex: 800,
      left: "173px",
      boxShadow: "2px 0px 7px rgba(0, 0, 0, 0.0867024)",
    },
  },
  { id: "vendorModel", label: "Model (OEM)" },
  {
    id: "chargerManufacturer",
    label: "Sold as manufacturer",
  },
  { id: "chargerModel", label: "Sold as model" },
  { id: "serialNumber", label: "ID (Serial number)" },
  { id: "accountName", label: "Account" },
  { id: "depotName", label: "Depot" },
  { id: "connectivityStatus", label: "Connectivity status" },
  { id: "chargePortsCount", label: "Ports" },
  { id: "networkType", label: "Network" },
  { id: "firmwareVersion", label: "Firmware version" },
  { id: "firmwareStatus", label: "Firmware status" },
  { id: "chargingMode", label: "Charging mode" },
  { id: "chargingStatus", label: "Charging status" },
  {
    id: "cllmStatus",
    label: (
      <div className="rfidStatus">
        <p>CLLM Status</p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title=<ul>
            <li>
              <b>Enabled:</b> Managed Charging in profile + Added to a circuit
              in the “Circuits/panels” tab of “Charging infrastructure”
            </li>
            <li>
              <b>Disabled:</b> Anything other than Enabled criteria.
            </li>
          </ul>
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
  },
  {
    id: "publicChargingStatus",
    label: (
      <div className="rfidStatus">
        <p>Public Charging Status</p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title=<ul>
            <li>
              <b>Enabled:</b> This charger is currently open for public charging
              and can be used by a public driver.
            </li>
            <li>
              <b>Capable:</b> This charger is capable of being open for public
              charging, but is not currently open to the public.
            </li>
            <li>
              <b>Incapable:</b> This charger does not allow for public charging,
              and will not be open for public charging because it is
              incompatible.
            </li>
          </ul>
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
  },
  {
    id: "chargerRfidStatus",
    label: (
      <div className="rfidStatus">
        <p>RFID status </p>
        <Tooltip
          arrow
          TransitionComponent={Zoom}
          title=<ul>
            <li>
              <b>Enabled:</b> This charger currently requires the tap of an
              authorized RFID card to start dispensing energy in a charge
              session.
            </li>
            <li>
              <b>Capable:</b> This charger does have a RFID reader, and will be
              RFID-enabled when the RFID controls are enabled for the profile.
            </li>
            <li>
              <b>Incapable:</b> This charger does not have a RFID reader, and
              will not be RFID-enabled, even if the profile has RFID access
              controls enabled.
            </li>
          </ul>
        >
          <InfoIcon fontSize="small" className="rfidStatusInfoIcon" />
        </Tooltip>
      </div>
    ),
  },
  { id: "isMinaEnrolled", label: "Mina enrolled" },
  { id: "isChargerDemandResponseEnabled", label: "Demand response" },
  { id: "energyServicePrograms", label: "Enrolled programs" },
  { disableSorting: true, id: "action", label: "Action" },
];

const Rows = React.memo((props) => {
  const {
    item,
    toggleDetailDrawer,
    handleChargerCredential,
    handleGetDiagnosticsConfig,
    toggleChargerDetailDrawer,
    setConfirmDialog,
    setDeleteConfirmDialog,
    setAlertConfirmDialog,
    replaceCharger,
    disconnectCharger,
    deleteCharger,
    filterObject,
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableRow hover={true} className="chargerDetailsTable">
        <TableCell
          id={"charger_name_cell" + item?.chargerId}
          align="center"
          className="chargerNameCell"
        >
          <a
            onClick={() => {
              toggleChargerDetailDrawer(true, "Details", item);
            }}
            className="chargerNameLink"
          >
            {item.chargerName || titleLabels.NOT_AVAILABLE}
          </a>
        </TableCell>
        <TableCell align="center" className="manufacturerCell">
          {item.vendorManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.vendorModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.chargerManufacturer || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>{item.chargerModel || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.serialNumber || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.accountName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>{item.depotName || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          {item.connectivityStatus || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell align="center">
          <Chip
            label={
              item.chargePortsCount === 0
                ? 0
                : item.chargePortsCount
                ? item.chargePortsCount
                : titleLabels.NOT_AVAILABLE
            }
            className="chargePortsCell"
          />
        </TableCell>
        <TableCell>{item.networkType || titleLabels.NOT_AVAILABLE}</TableCell>
        <TableCell>
          <a
            onClick={() =>
              toggleDetailDrawer(true, "Firmware History", item, "")
            }
            className="firmwareHistoryCell"
          >
            {item.isTestVersion && (
              <ScienceIcon fontSize="small" className="testVersionIcon" />
            )}{" "}
            {item.firmwareVersion || titleLabels.NOT_AVAILABLE}
          </a>
        </TableCell>
        <TableCell>
          <div
            className={
              item.firmwareStatus === "upToDate"
                ? "up_to_date"
                : item.firmwareStatus === "updateAvailable"
                ? "update_available"
                : "NA_or_failed"
            }
          >
            {firmwareStatusEnum[item.firmwareStatus] ||
              titleLabels.NOT_AVAILABLE}
          </div>
        </TableCell>
        <TableCell>
          {chargingModeEnum[item.chargingMode] || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          {item.chargingStatus || titleLabels.NOT_AVAILABLE}
        </TableCell>
        <TableCell>
          <Chip
            label={item?.cllmStatus || "Disabled"}
            style={{
              color: pcColors(item?.cllmStatus),
              background: pcBGColor(item?.cllmStatus),
            }}
          />
        </TableCell>
        <TableCell>
          <Chip
            label={item?.publicChargingStatus}
            style={{
              color: pcColors(item?.publicChargingStatus),
              background: pcBGColor(item?.publicChargingStatus),
            }}
          />
        </TableCell>
        <TableCell>
          <Chip
            avatar={
              item?.rfidDetails?.rfidConfigMode &&
              item?.rfidDetails?.isConfigMatched == false && (
                <WarningIcon fontSize="small" style={{ color: "#916D1F" }} />
              )
            }
            label={item?.rfidDetails?.status}
            style={{
              color: rfidColors(item?.rfidDetails),
              background: rfidBGColor(item?.rfidDetails),
            }}
          />
          {item?.rfidDetails?.rfidConfigMode &&
            item?.rfidDetails?.isConfigMatched == false && (
              <p className="RFID_color_sub_title">
                {item?.rfidDetails?.rfidConfigMode} config needed
              </p>
            )}
        </TableCell>
        <TableCell align="center">
          <Chip
            label={item.isMinaEnrolled ? "Yes" : "No"}
            style={{
              color: item.isMinaEnrolled ? "#00765E" : "#D01300",
              background: item.isMinaEnrolled ? "#E5F1EF" : "#FAE7E5",
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Chip
            label={item.isChargerDemandResponseEnabled ? "Yes" : "No"}
            style={{
              color: item.isChargerDemandResponseEnabled
                ? "#00765E"
                : "#D01300",
              background: item.isChargerDemandResponseEnabled
                ? "#E5F1EF"
                : "#FAE7E5",
            }}
          />
        </TableCell>
        <TableCell align="center">
          {item?.energyServicePrograms?.length
            ? item.energyServicePrograms.map((program) => (
                <Chip
                  label={program}
                  style={{
                    color: "#18191E",
                    background: "#F0F1F8",
                  }}
                />
              ))
            : "-"}
        </TableCell>
        <TableCell>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title="View last known OCPP settings"
          >
            <span>
              <Controls.ActionButton
                color="primary"
                onClick={() =>
                  toggleDetailDrawer(true, "Last known OCPP settings", item, "")
                }
              >
                <InfoOutlinedIcon fontSize="small" />
              </Controls.ActionButton>
            </span>
          </Tooltip>
          <PrivilegedComponent
            permission="Charger Credential"
            module="chargerDetails"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title="Get charger's credential"
            >
              <span>
                <Controls.ActionButton
                  color="primary"
                  onClick={() =>
                    handleChargerCredential(
                      item.chargerName,
                      item.depotId,
                      item.objectId,
                      item.chargerId
                    )
                  }
                >
                  <VisibilityIcon fontSize="small" />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>

          <PrivilegedComponent
            permission="Request Diagnostics"
            module="chargerDetails"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title="Request diagnostics"
            >
              <span>
                <Controls.ActionButton
                  color="primary"
                  onClick={() =>
                    handleGetDiagnosticsConfig(
                      item.depotId,
                      item.chargerId,
                      item.chargerMetaId,
                      item.soldAsId,
                      item.accountId
                    )
                  }
                >
                  <span className="vimg">
                    <img
                      color="primary"
                      src={DiagnosticsConfigIcon}
                      alt="Request Diagnostics"
                    />
                  </span>
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>

          <PrivilegedComponent
            permission="Disconnect Charger"
            module="chargerDetails"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={
                item.isChargerDisconnected ||
                !(
                  item?.connectivityStatus === "Not Connected" &&
                  item?.chargingStatus === "Charging"
                )
                  ? "Charger cannot be disconnected"
                  : "Disconnect charger"
              }
            >
              <span>
                <Controls.ActionButton
                  disabled={
                    item.isChargerDisconnected ||
                    !(
                      item?.connectivityStatus === "Not Connected" &&
                      item?.chargingStatus === "Charging"
                    )
                  }
                  color="primary"
                  onClick={() =>
                    setConfirmDialog({
                      isOpen: true,
                      title:
                        "Are you sure you want to disconnect this charger ?",
                      subTitle: (
                        <div>
                          <p>
                            This operation will disconnect the ongoing charging
                            session from FDC dashboard. This will not affect the
                            actual charging session if any. <br />
                            This operation is to be used when,
                            <ul>
                              <li>
                                Our system does not receive information of end
                                of the session from charger.
                              </li>
                              <li>
                                When charger was repointed or no longer
                                connected to our system while the session was
                                still going on.
                              </li>
                            </ul>
                            This operation will generate CDRs and the
                            information would not match reality since its a
                            forceful virtual disconnection of a session
                          </p>
                        </div>
                      ),
                      type: "secondary",
                      onConfirm: () => disconnectCharger(item),
                    })
                  }
                >
                  <LinkOffIcon fontSize="small" />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <PrivilegedComponent
            permission="Manage Charger"
            module="chargerDetails"
          >
            <Tooltip
              arrow
              TransitionComponent={Zoom}
              title={"Edit or Delete Charger"}
            >
              <span>
                <Controls.ActionButton color="primary" onClick={handleClick}>
                  <MoreVertIcon fontSize="small" color={"#1DB954"} />
                </Controls.ActionButton>
              </span>
            </Tooltip>
          </PrivilegedComponent>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                toggleDetailDrawer(true, "EDIT CHARGER", item, "EDIT"),
                  handleClose();
              }}
            >
              Edit charger details
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (item.chargingStatus === "Charging") {
                  setAlertConfirmDialog({
                    isOpen: true,
                    title:
                      "This charger cannot be deleted while a vehicle is plugged into it.",
                    subTitle:
                      "One or more ports connected to this charger is plugged into a vehicle. To delete this charger, please unplug all vehicles from its ports and try again.",
                    type: "secondary",
                  });
                } else {
                  setDeleteConfirmDialog({
                    isOpen: true,
                    title: "Delete Charger",
                    subTitle:
                      "This charger is a replacement for an existing Ford Pro charger under warranty?The associated ports will also be deleted. Are you sure you want to delete the selected charger?",
                    type: "secondary",
                    onConfirm: () => deleteCharger(item, filterObject),
                  });
                }

                handleClose();
              }}
            >
              Delete charger
            </MenuItem>
            <MenuItem>
              <PrivilegedComponent
                permission="Replace Charger"
                module="chargerDetails"
              >
                <Tooltip
                  arrow
                  TransitionComponent={Zoom}
                  title={
                    item?.isChargerReplaced
                      ? "Charger already replaced"
                      : "This will prevent generation of an onboarding fee for the associated ports."
                  }
                >
                  <span
                    style={{
                      cursor: item?.isChargerReplaced
                        ? "not-allowed"
                        : "pointer",
                      color: item?.isChargerReplaced ? "gray" : "inherit",
                    }}
                    onClick={() =>
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure ?",
                        subTitle:
                          "This charger is a replacement for an existing Ford Pro charger under warranty?",
                        type: "secondary",
                        onConfirm: () => replaceCharger(item),
                      })
                    }
                  >
                    Mark as replacement
                  </span>
                </Tooltip>
              </PrivilegedComponent>
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
});

const ToolbarMemoised = React.memo(
  ({
    handleSearch,
    className,
    toggleDrawer,
    setOpenPopup,
    isFiltered,
    toggleDetailDrawer,
    data,
    filterObject,
    result,
    getAllChargersAndFirmware,
    searchedString,
    rowsPerPage,
  }) => {
    const history = useHistory();
    const options = ["None", "Atria", "Callisto", "Dione"];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Toolbar className="table_toolbar">
        <Controls.Input
          id="charger_details_search"
          label={"Search"}
          className={className}
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              result.page !== 0
                ? result.setPage(0)
                : getAllChargersAndFirmware(
                    filterObject,
                    `?search=${searchedString}&page=0&limit=${rowsPerPage}`,
                    true
                  );
            }
          }}
        />
        <div style={{ marginRight: "10px" }}>
          <Controls.Button
            type="button"
            text="Search"
            size="small"
            onClick={() => {
              result.page !== 0
                ? result.setPage(0)
                : getAllChargersAndFirmware(
                    filterObject,
                    `?search=${searchedString}&page=0&limit=${rowsPerPage}`,
                    true
                  );
            }}
          />
        </div>
        <Badge
          color="primary"
          variant="dot"
          invisible={isFiltered ? false : true}
        >
          <Controls.Button
            text="Filter"
            id="charger_details_filter"
            variant="outlined"
            startIcon={
              <TuneIcon
                style={{
                  color: isFiltered ? "#2770D8" : "#A3B6C7",
                }}
              />
            }
            onClick={() => {
              toggleDrawer(true, "FILTER", {});
            }}
            className="filter"
            style={{
              border: isFiltered ? "1px solid #2770D8" : "1px solid #A3B6C7",
            }}
          />
        </Badge>
        <Grid sm item />
        <IconButton
          aria-label="more"
          id="long-button"
          className="moreVertIcon_btn"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "20ch",
            },
          }}
        >
          <MenuItem
            key="Export CSV"
            id="export_csv_button"
            disabled={data.length === 0}
            onClick={() => {
              setOpenPopup({
                isOpen: true,
                title: "Export CSV",
                child: "exportCSV",
                // item: item,
              });
            }}
          >
            Export CSV
          </MenuItem>
          <MenuItem
            key="Charger History"
            id="charger_history_button"
            onClick={() => history.push(routePaths.CHARGER_HISTORY)}
          >
            Charger History
          </MenuItem>
          <MenuItem
            key="Replacement History"
            id="replacement_history_button"
            onClick={() => history.push(routePaths.CHARGER_REPLACEMENT_HISTORY)}
          >
            Replacement History
          </MenuItem>
        </Menu>

        <PrivilegedComponent
          permission="Manage Charger"
          module="chargerDetails"
        >
          <Controls.Button
            text="Add Charger"
            id="add_charger_btn"
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => {
              toggleDetailDrawer(true, "ADD CHARGER", {}, "ADD"), handleClose();
            }}
          />
        </PrivilegedComponent>
      </Toolbar>
    );
  }
);

const RowsMemoised = React.memo((props) => {
  return <Rows {...props} />;
});

const TableBodyMemoised = React.memo(
  ({
    data,
    toggleDetailDrawer,
    toggleChargerDetailDrawer,
    setConfirmDialog,
    setDeleteConfirmDialog,
    setAlertConfirmDialog,
    replaceCharger,
    disconnectCharger,
    deleteCharger,
    filterObject,
    handleChargerCredential,
    handleGetDiagnosticsConfig,
  }) => {
    return (
      <TableBody>
        {data.map((item, index) => (
          <RowsMemoised
            item={item}
            toggleDetailDrawer={toggleDetailDrawer}
            toggleChargerDetailDrawer={toggleChargerDetailDrawer}
            setConfirmDialog={setConfirmDialog}
            setDeleteConfirmDialog={setDeleteConfirmDialog}
            setAlertConfirmDialog={setAlertConfirmDialog}
            replaceCharger={replaceCharger}
            disconnectCharger={disconnectCharger}
            deleteCharger={deleteCharger}
            filterObject={filterObject}
            handleChargerCredential={handleChargerCredential}
            handleGetDiagnosticsConfig={handleGetDiagnosticsConfig}
          />
        ))}
      </TableBody>
    );
  },
  function (prevProps, nextProps) {
    if (isEqual(prevProps.data, nextProps.data)) {
      return true;
    }
    return false;
  }
);

const ChargerFirmwareTable = (props) => {
  const {
    allChargers,
    toggleDrawer,
    setConfirmDialog,
    replaceCharger,
    disconnectCharger,
    isFiltered,
    allMakes,
    allAccounts,
    setToast,
    getAllChargersAndFirmware,
    filterObject,
    setLoading,
    loading,
    setPortCount,
    totalCount,
    setTotalCount,
    resetPage,
    setResetPage,
    rowsPerPage,
    setRowsPerPage,
    isFilterDataLoaded,
  } = props;
  const classes = useStyles();
  const [filterFn, setFilterFn] = React.useState({ fn: (items) => items });
  const toggleDetailDrawer = (openClose, title, item, forWhat) => {
    setDrawerOC({
      isOpen: openClose,
      title: title,
      data: item,
      reason: forWhat,
    });
  };
  const { CHARGER_PROFILE, GET_CHARGERS_TOTAL_COUNT } = indexedEndPoints;
  const toggleChargerDetailDrawer = (openClose, title, item) => {
    setChargerDrawerOC({ isOpen: openClose, title: title, data: item });
  };
  const [TableContainer, setTableContainer] = React.useState("table");
  const [TableHead, setTableHead] = React.useState("thead");
  const [TablePagination, setTablePagination] = React.useState(() => <></>);
  const [recordsAfterPagingAndSorting, setRecordsAfterPagingAndSorting] =
    React.useState([]);
  const [page, setPage] = React.useState(0);
  const [searchedString, setSearchedString] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [extraPaginationProps, setExtraPaginationProps] = React.useState({});
  const [DrawerOC, setDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [DraweChargerDetails, setChargerDrawerOC] = React.useState({
    isOpen: false,
    title: "",
    data: {},
  });

  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [alertConfirmDialog, setAlertConfirmDialog] = React.useState({
    isOpen: false,
    type: "primary",
  });

  const [openPopup, setOpenPopup] = React.useState({
    isOpen: false,
    title: "",
    child: "",
    item: {},
  });

  useEffect(() => {
    // reset page on filter change
    if (resetPage) {
      result.setPage(0);
      setResetPage(false);
    }
  }, [resetPage]);

  useEffect(() => result.setCount(totalCount), [totalCount]);

  const getTotalCount = async () => {
    const queryparam = searchedString !== "" ? `?search=${searchedString}` : "";
    setLoading(true);
    const res = await apiProvider.post(
      `${GET_CHARGERS_TOTAL_COUNT}${queryparam}`,
      filterObject
    );
    setLoading(false);
    if (res.statusCode >= 200 && res.statusCode <= 299) {
      setTotalCount(res?.data?.chargersCount);
    }
  };

  useEffect(() => {
    if (allChargers?.length < rowsPerPage) {
      const totalRecords = rowsPerPage * page + allChargers?.length;
      setTotalCount(totalRecords);
      setExtraPaginationProps({
        ...extraPaginationProps,
        nextIconButtonProps: { disabled: true },
        labelDisplayedRows: (from) => (
          <p>
            {from}-{totalRecords} of {totalRecords}
          </p>
        ),
      });
    } else
      setExtraPaginationProps({
        ...extraPaginationProps,
        nextIconButtonProps: {},
        labelDisplayedRows: (from, to) => (
          <p className="centerAligned">
            {from}-{to} of
            {totalCount ? (
              ` ${totalCount}`
            ) : (
              <Button className="noTextTransform" onClick={getTotalCount}>
                Load Count
              </Button>
            )}
          </p>
        ),
      });
  }, [allChargers, totalCount]);

  const result = useTable(
    allChargers,
    headCells,
    filterFn,
    false,
    true,
    null,
    null,
    -1, // -1 is used for backend pagination if totalCount is not available. Do not change this.
    true,
    50,
    null,
    "asc",
    extraPaginationProps
  );

  React.useEffect(() => {
    setTableContainer(() => result.tableContainer);
  }, [result.tableContainer]);
  React.useEffect(() => {
    setTableHead(() => result.tableHead);
  }, [result.tableHead]);
  React.useEffect(() => {
    setTablePagination(result.tablePagination);
  }, [result.tablePagination]);
  React.useEffect(() => {
    setRecordsAfterPagingAndSorting(result.recordsAfterPagingAndSorting);
  }, [result.recordsAfterPagingAndSorting]);
  React.useEffect(
    () => setRowsPerPage(result.rowsPerPage),
    [result.rowsPerPage]
  );
  React.useEffect(() => setPage(result.page), [result.page]);
  React.useEffect(() => setOrder(result.order), [result.order]);
  React.useEffect(() => setOrderBy(result.orderBy), [result.orderBy]);

  React.useEffect(() => {
    let queryparam = `?page=${page}&limit=${rowsPerPage}`;
    if (searchedString !== "") queryparam += `&search=${searchedString}`;
    if (orderBy && order) {
      queryparam += `&orderBy=${orderBy}&order=${order == "asc" ? "1" : "-1"}`;
    }
    isFilterDataLoaded &&
      getAllChargersAndFirmware(filterObject, queryparam, false);
  }, [page, rowsPerPage, order, orderBy]);

  const handleSearch = (e) => setSearchedString(e.target?.value?.trim());

  const handleChargerCredential = async (
    chargerName,
    depotId,
    objectId,
    chargerId
  ) => {
    setLoading(true);
    const response = await getCredentialByCharger(depotId, objectId, chargerId);
    const data = { ...response.data, chargerName };
    setLoading(false);
    if (response.statusCode >= 200 && response.statusCode <= 299) {
      toggleDetailDrawer(true, "Charger's credential", data, "");
    } else {
      setToast({
        isOpen: true,
        message: "Failed to retrieve charger credentials",
        type: "error",
      });
    }
  };

  const handleGetDiagnosticsConfig = async (
    depotId,
    chargerId,
    chargerMetaId,
    soldAsId,
    companyId
  ) => {
    const stopTime = new Date().toISOString();
    let startTime = new Date();
    startTime.setUTCDate(startTime.getUTCDate() - 90);
    startTime = startTime.toISOString();
    let expiresAt = new Date();
    expiresAt.setUTCDate(expiresAt.getUTCDate() + 7);
    expiresAt = expiresAt.toISOString();

    const notes = "Triggered from ChargerDetails Page";

    const getDiagnosticsRequestBody = {
      endpoint: "getDiagnostics",
      requestBody: {
        protocolType: "FTP",
        startTime,
        stopTime,
        id: chargerId,
        retries: 1,
        retryInterval: 30,
      },
      depotId,
      customerId: companyId,
      requestedBy: getData("user_email"),
      notes,
      expiresAt,
    };

    const getConfigurationRequestBody = {
      endpoint: "getConfiguration",
      depotId,
      customerId: companyId,
      requestBodyList: [
        {
          id: chargerId,
          connectorId: 1,
          chargerMetaId,
          soldAsId,
        },
      ],
      notes,
      expiresAt,
    };

    const [diagnosticsResponse, configurationResponse] =
      await Promise.allSettled([
        executeInternalApis(
          checkIsPreprovisioningDepot(depotId),
          "single",
          getDiagnosticsRequestBody
        ),
        executeInternalApis(checkIsPreprovisioningDepot(depotId), "bulk", [
          getConfigurationRequestBody,
        ]),
      ]);
    if (
      diagnosticsResponse?.value?.statusCode >= 200 &&
      diagnosticsResponse?.value?.statusCode <= 299 &&
      configurationResponse?.value?.statusCode >= 200 &&
      configurationResponse?.value?.statusCode <= 299
    ) {
      setToast({
        isOpen: true,
        message: "Service Execution Successful",
        type: "success",
      });
    } else {
      setToast({
        isOpen: true,
        message: "Service Execution Failed",
        type: "error",
      });
    }
  };

  const checkIsPreprovisioningDepot = (depotId) =>
    environment?.[
      process.env.REACT_APP_NODE_ENV
    ]?.PRE_PROVISIONING_DEPOTS?.find((ele) => ele.depotId === depotId)
      ? true
      : false;

  const deleteCharger = useCallback(async (item, filterObject) => {
    setLoading(true);
    const response = await apiProvider.del(
      `${CHARGER_PROFILE}/${item.depotId}/${item.objectId}`,
      { chargerId: item.chargerId }
    );

    if (response.statusCode >= 200 && response.statusCode <= 299) {
      setToast({
        isOpen: true,
        message: "Charger Deleted",
        type: "success",
      });
      getAllChargersAndFirmware(filterObject);
    } else {
      setToast({
        isOpen: true,
        message: "Charger deleting failed",
        type: "error",
      });
    }
    setDeleteConfirmDialog({ ...deleteConfirmDialog, isOpen: false });
    setLoading(false);
  }, []);

  return (
    <>
      <ToolbarMemoised
        handleSearch={handleSearch}
        className="searchInput"
        toggleDrawer={toggleDrawer}
        setOpenPopup={setOpenPopup}
        isFiltered={isFiltered}
        recordsAfterPagingAndSorting={recordsAfterPagingAndSorting}
        toggleDetailDrawer={toggleDetailDrawer}
        data={result?.searchData}
        filterObject={filterObject}
        result={result}
        getAllChargersAndFirmware={getAllChargersAndFirmware}
        searchedString={searchedString}
        rowsPerPage={rowsPerPage}
      />

      <TableContainer>
        <TableHead />
        <React.Fragment>
          <TableBodyMemoised
            data={recordsAfterPagingAndSorting}
            toggleDetailDrawer={toggleDetailDrawer}
            toggleChargerDetailDrawer={toggleChargerDetailDrawer}
            handleChargerCredential={handleChargerCredential}
            handleGetDiagnosticsConfig={handleGetDiagnosticsConfig}
            setConfirmDialog={setConfirmDialog}
            setDeleteConfirmDialog={setDeleteConfirmDialog}
            setAlertConfirmDialog={setAlertConfirmDialog}
            replaceCharger={replaceCharger}
            disconnectCharger={disconnectCharger}
            deleteCharger={deleteCharger}
            filterObject={filterObject}
          />
        </React.Fragment>
      </TableContainer>
      {recordsAfterPagingAndSorting.length === 0 && (
        <div className="no_data_in_table">
          <div className="img_title">
            <img src={no_Data} alt="No data available" />
            <p className="no_data_title">No data available</p>
          </div>
        </div>
      )}
      {TablePagination}

      <CommonDrawer DrawerOC={DrawerOC} toggleDrawer={toggleDetailDrawer}>
        {DrawerOC.title === "Firmware History" && (
          <FirmwareHistorySideBar DrawerOC={DrawerOC} />
        )}
        {DrawerOC.title === "Last known OCPP settings" && (
          <ChargerLastKnownOCPP DrawerOC={DrawerOC} setToast={setToast} />
        )}
        {DrawerOC.title === "Charger's credential" && (
          <ChargerCredential DrawerOC={DrawerOC} setToast={setToast} />
        )}
      </CommonDrawer>
      {(DrawerOC.title === "EDIT CHARGER" ||
        DrawerOC.title === "ADD CHARGER") && (
        <NewCommonFilterDrawer
          DrawerOC={DrawerOC}
          toggleDrawer={toggleDetailDrawer}
        >
          <EditChargerSideBar
            DrawerOC={DrawerOC}
            allMakes={allMakes}
            allAccounts={allAccounts}
            setToast={setToast}
            setLoading={setLoading}
            loading={loading}
            getAllChargersAndFirmware={getAllChargersAndFirmware}
            filterObject={filterObject}
            toggleDetailDrawer={toggleDetailDrawer}
          />
        </NewCommonFilterDrawer>
      )}
      <ChargerDetailDrawer
        DraweChargerDetails={DraweChargerDetails}
        toggleDrawer={toggleChargerDetailDrawer}
      >
        {DraweChargerDetails.title === "Details" && (
          <ChargerdetailsSideBar
            DraweChargerDetails={DraweChargerDetails}
            allAccounts={allAccounts}
            setLoading={setLoading}
            loading={loading}
            setPortCount={setPortCount}
          />
        )}
      </ChargerDetailDrawer>

      {deleteConfirmDialog.isOpen && (
        <DeleteConfirmDialog
          deleteConfirmDialog={deleteConfirmDialog}
          setDeleteConfirmDialog={setDeleteConfirmDialog}
          loading={loading}
        />
      )}

      {alertConfirmDialog.isOpen && (
        <AlertDialog
          alertConfirmDialog={alertConfirmDialog}
          setAlertConfirmDialog={setAlertConfirmDialog}
        />
      )}

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        {openPopup.child === "exportCSV" ? (
          <ChargersExportCSV
            setOpenPopup={setOpenPopup}
            recordsAfterPagingAndSorting={result?.searchData?.map((row) => ({
              ...row,
              vendorManufacturer:
                row.vendorManufacturer || titleLabels.NOT_AVAILABLE,
              vendorModel: row.vendorModel || titleLabels.NOT_AVAILABLE,
              deratedMaxAmps:
                row.deratedMaxAmps >= 0
                  ? row.deratedMaxAmps
                  : titleLabels.NOT_AVAILABLE,
              chargingMode:
                chargingModeEnum[row.chargingMode] || titleLabels.NOT_AVAILABLE,
              firmwareStatus:
                firmwareStatusEnum[row.firmwareStatus] ||
                titleLabels.NOT_AVAILABLE,
              portsCount:
                row?.chargePortsCount >= 0
                  ? row?.chargePortsCount
                  : titleLabels.NOT_AVAILABLE,
              firstCommunication: row.firstCommunication
                ? row.firstCommunication?.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              billingActivatedAt: row.billingActivatedAt
                ? row.billingActivatedAt.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              lastAssemblyCheckinTime: row.lastAssemblyCheckinTime
                ? row.lastAssemblyCheckinTime.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              lastFirmwareUpdatedAt: row.lastFirmwareUpdatedAt
                ? row.lastFirmwareUpdatedAt.replace("GMT", "UTC")
                : titleLabels.NOT_AVAILABLE,
              energyServicePrograms: row.energyServicePrograms?.length
                ? row.energyServicePrograms?.join(",")
                : "-",
              isMinaEnrolled: row.isMinaEnrolled === true ? "Yes" : "No",
              isAdvenirEnrolled: row.isAdvenirEnrolled === true ? "Yes" : "No",
            }))}
          />
        ) : null}
      </Popup>
    </>
  );
};

export default ChargerFirmwareTable;
